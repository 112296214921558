var fieldsDefinition = {
    phonebookEntryExtended: {
        label: '',
        type: 'boolean'
    },
    phonebookEntry: {
        label: '',
        type: 'boolean'
    },
    phonebook: {
        label: 'Telefonbucheintrag',
        type: 'text',
        options: [
            {
                label: 'Nein, ich wünsche keinen Eintrag',
                value: 'Nein'
            },
            {
                label: 'Ja, Ich wünsche die Aufnahme meiner Daten wie folgt (Mehrfachnennung möglich)',
                value: 'Ja'
            }
        ],
        validation: {
            required: true
        }
    },
    phonebookPrint: {
        label: 'Eintrag in das gedruckte Telefonbuch',
        type: 'boolean'
    },
    phonebookDigital: {
        label: 'Eintrag in die elektronischen Auskunftsmedien',
        type: 'boolean'
    },
    phonebookEnquiry: {
        label: 'Eintrag bei der telefonischen Auskunft',
        type: 'boolean'
    },
    phonebookEnquiryType: {
        label: '',
        type: 'text',
        options: [
            {
                label: 'nur Nummer',
                value: '1'
            },
            {
                label: 'erweitert mit Name und Adresse',
                value: '2'
            }
        ]
    },
    phonebookDenyReverseSearch: {
        label: 'Ich widerspreche der Rückwärts-/Inverssuche ',
        type: 'boolean'
    },
    previous_provideList: {
        label: 'bisheriger Anbieter',
        type: 'text',
        validation: {
            required: [null, 'isNumberPortabilityNew']
        }
    },
    reasonToChangeMoveExistingCustomer: {
        label: '',
        type: 'boolean'
    },
    newCustomer: {
        label: 'Ich bin Neukunde',
        type: 'boolean'
    },
    showPreviousProvider: {
        label: '',
        type: 'boolean'
    },
    previous_provider: {
        label: 'bisheriger Anbieter',
        type: 'text',
        validation: {
            required: ['Bitte geben Sie einen bisherigen Anbieter ein.', 'isShowPreviousProviderCustom']
        }
    },
    showPreviousTenant: {
        label: '',
        type: 'boolean'
    },
    previousTenant_firstname: {
        label: 'Vorname',
        type: 'text'
    },
    previousTenant_lastname: {
        label: 'Nachname',
        type: 'text',
        validation: {
            required: 'Bitte geben Sie einen Nachnamen ein.'
        }
    },
    previousSubscriber_firstname: {
        label: 'Vorname',
        type: 'text',
        validation: {
            max: [40, "Es sind nur 40 max Zeichen erlaubt"]
        }
    },
    previousSubscriber_lastname: {
        label: 'Nachname',
        type: 'text',
        validation: {
            max: [40, "Es sind nur 40 max Zeichen erlaubt"]
        }
    },
    previousSubscriber_company: {
        label: 'Firma',
        type: 'text',
        validation: {
            max: [40, "Es sind nur 40 max Zeichen erlaubt"]
        }
    },
    showPreviousSubscriber: {
        label: '',
        type: 'boolean'
    },
    fn_calendarWidget: {
        label: '',
        type: 'boolean'
    },
    offerMode: {
        label: '',
        type: 'boolean'
    },
    tariffChange: {
        label: '',
        type: 'boolean'
    },
    telcoProduct: {
        label: '',
        type: 'boolean'
    },
    evn: {
        type: 'text',
        label: 'Wünschen Sie einen Einzelverbindungsnachweis?',
        options: [
            { label: 'Nein, ich wünsche keinen EVN', value: 'Nein, ich wünsche keinen EVN' },
            {
                label: 'Ja, ich wünsche einen EVN gekürzt um 3 Stellen',
                value: 'Ja, ich wünsche einen EVN gekürzt um 3 Stellen'
            },
            { label: 'Ja, ich wünsche einen EVN ungekürzt', value: 'Ja, ich wünsche einen EVN ungekürzt' }
        ],
        validation: {
            required: true
        }
    },
    telco: {
        label: '',
        type: 'boolean'
    },
    isHTNT: {
        type: 'boolean',
        label: '',
        options: [
            { value: false, label: 'j' },
            { value: true, label: 'n' }
        ]
    },
    reasonToChange: {
        label: '',
        type: 'text',
        options: [
            {
                label: 'weil ich einen neuen Anbieter/Tarif möchte',
                value: 'E03'
            },
            {
                label: 'weil ich umziehe',
                value: 'E01'
            }
        ],
        validation: {
            required: true
        }
    },
    subscriberDirectory: {
        label: 'Eintrag Teilnehmerverzeichnis',
        type: 'text',
        options: [
            {
                label: 'Nein, ich wünsche keinen Eintrag',
                value: 'Nein'
            },
            {
                label: 'Ja, ich wünsche einen Eintrag ins Teilnehmerverzeichnis',
                value: 'Ja'
            }
        ]
    },
    prevSupplier: {
        label: '',
        type: 'text',
        options: [],
        validation: {
            required: [null, 'isNewTariff']
        }
    },
    prevCustomerNum: {
        label: '',
        type: 'text'
    },
    terminatePrevSupplier: {
        label: 'Kündigung Versorger',
        type: 'text',
        options: [
            {
                value: 'false',
                label: 'Ich bevollmächtige %versorgername% zur Kündigung meines bisherigen Liefervertrages'
            },
            { value: 'true', label: 'Ich habe bereits gekündigt' }
        ]
    },
    dateOfCancellation: {
        label: 'Kündigungsdatum',
        type: 'text',
        validation: {
            required: [null, 'isAlreadyTerminated']
        }
    },
    meterNumber: {
        label: '',
        type: 'text',
        validation: {
            required: [null, 'isNewTariff' || 'isChangeTariff']
        }
    },
    maLoID: {
        label: 'Marktlokationsnummer',
        type: 'text'
    },
    numberPortability: {
        label: 'Rufnummer(n)',
        type: 'text',
        options: [
            {
                label: 'neue Rufnummer (Sie erhalten die von Ihnen gewünschte Anzahl an Rufnummern)',
                value: 'new'
            },
            {
                label: 'bestehende Rufnummer(n) portieren Bitte tragen Sie Ihre bestehenden Rufnummer(n) mit Vorwahl hier ein.',
                value: 'old'
            }
        ]
    },
    prevTelNumber: {
        label: 'Format: 030123456',
        type: 'text',
        validation: {
            required: [null, 'isNumberPortabilityNew'],
            regex: ['^[0-9, ]*$', 'dieses Feld darf nur Zahlen beinhalten']
        }
    },
    startOfDelivery: {
        label: 'Gewünschter Bereitstellungstermin',
        type: 'text',
        options: [
            {
                label: 'nächstmöglicher Termin',
                value: 'nextPossibleDate'
            },
            {
                label: 'Wunschtermin',
                value: 'desiredDate'
            }
        ]
    },
    desiredDate: {
        label: 'TT.MM.JJJJ',
        type: 'text',
        validation: {
            required: [null, 'DesiredDate']
        }
    },
    newMeterNumber: {
        label: '',
        type: 'text',
        validation: {
            required: [null, 'isMoveOut']
        }
    },
    dateMoveIn: {
        label: 'Datum des Einzugs',
        type: 'text',
        validation: {
            required: [null, 'isMoveOut']
        }
    },
    currentMeterReading: {
        label: 'Aktueller Zählerstand',
        type: 'number'
    },
    currentMeterReadingHT: {
        label: 'Aktueller Zählerstand HT',
        type: 'number'
    },
    currentMeterReadingNT: {
        label: 'Aktueller Zählerstand NT',
        type: 'number'
    }
};
var preSupplierForm = {
    title: '',
    fields: fieldsDefinition,
    initialValues: {
        fn_calendarWidget: false,
        telco: false,
        isHTNT: false,
        reasonToChange: 'E03' || 'tarifwechsel',
        terminatePrevSupplier: 'false',
        startOfDelivery: 'nextPossibleDate',
        numberPortability: 'new',
        telcoProduct: false,
        subscriberDirectory: 'Nein',
        phonebook: 'Nein',
        phonebookEnquiry: false,
        phonebookEnquiryType: '2',
        offerMode: false,
        tariffChange: false,
        showPreviousSubscriber: false,
        showPreviousTenant: false,
        showPreviousProvider: false,
        previousTenant_lastname: 'Unbekannt',
        newCustomer: true,
        reasonToChangeMoveExistingCustomer: false,
        phonebookEntryExtended: false,
        phonebookEntry: true
    },
    conditions: {
        isReasonToChangeMoveExistingCustomer: { fieldName: 'reasonToChangeMoveExistingCustomer', type: 'equals', value: true },
        isNewCustomer: { fieldName: 'newCustomer', type: 'equals', value: true },
        isTelcoProduct: { fieldName: 'telcoProduct', type: 'equals', value: true },
        isTelco: { fieldName: 'telco', type: 'equals', value: true },
        isTariff: { fieldName: 'telco', type: 'equals', value: false },
        isMoveOut: { fieldName: 'reasonToChange', type: 'equals', value: 'E01' },
        isNotMoveOut: { fieldName: 'reasonToChange', type: 'equals', value: 'E03' },
        isChangeTariff: { fieldName: 'reasonToChange', type: 'equals', value: 'tarifwechsel' },
        isNewTariff: { fieldName: 'reasonToChange', type: 'equals', value: 'E03' },
        isDesiredDate: { fieldName: 'startOfDelivery', type: 'equals', value: 'desiredDate' },
        isAlreadyTerminated: { fieldName: 'terminatePrevSupplier', type: 'equals', value: 'true' },
        isHTNT: { fieldName: 'isHTNT', type: 'equals', value: false },
        isNumberPortabilityNew: { fieldName: 'numberPortability', type: 'equals', value: 'old' },
        show_calendarWidget: { fieldName: 'fn_calendarWidget', type: 'equals', value: true },
        showOfferButton: { fieldName: 'offerMode', type: 'equals', value: true },
        tariffChange: { fieldName: 'reasonToChange', type: 'equals', value: 'tarifwechsel' },
        isShowPreviousSubscriber: { fieldName: 'showPreviousSubscriber', type: 'equals', value: true },
        isShowPreviousTenant: { fieldName: 'showPreviousTenant', type: 'equals', value: true },
        isShowPreviousProvider: { fieldName: 'showPreviousProvider', type: 'equals', value: true },
        isShowPreviousProviderCustom: { fieldName: 'previous_provideList', type: 'equals', value: 'Custom' },
        showPhonebook: { fieldName: 'phonebook', type: 'equals', value: 'Ja' },
        isPhonebookEnquiry: { fieldName: 'phonebookEnquiry', type: 'equals', value: true },
        showPhonebookEntryExtended: { fieldName: 'phonebookEntryExtended', type: 'equals', value: true },
        showPhonebookEntry: { fieldName: 'phonebookEntry', type: 'equals', value: true }
    },
    structure: [
        {
            type: 'FormConditionalElement',
            props: { conditionName: 'isTariff' },
            children: [
                {
                    type: 'FormCard',
                    props: {},
                    children: [
                        {
                            type: 'FormRow',
                            props: { classes: 'tr-flex-wrap text-left' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'reasonToChange',
                                            input: 'radio'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                { type: 'FormDiv', props: { class: 'q-my-lg' }, children: [] },
                {
                    type: 'FormConditionalElement',
                    props: { conditionName: 'isNewTariff' },
                    children: [
                        {
                            type: 'FormCard',
                            props: { title: 'Vorversorgerdaten' },
                            children: [
                                {
                                    type: 'FormRow',
                                    props: { justify: 'center', classes: 'tr-flex-wrap text-left' },
                                    children: [
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12 },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    name: 'prevSupplier',
                                                    input: 'select',
                                                    props: {
                                                        filterable: true
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12 },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    name: 'prevCustomerNum',
                                                    input: 'text'
                                                }
                                            ]
                                        },
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12 },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    name: 'terminatePrevSupplier',
                                                    input: 'radio'
                                                },
                                                {
                                                    type: 'FormConditionalElement',
                                                    props: { conditionName: 'isAlreadyTerminated' },
                                                    children: [
                                                        {
                                                            type: 'FormConditionalElement',
                                                            props: { conditionName: 'show_calendarWidget' },
                                                            children: [
                                                                {
                                                                    type: 'FormField',
                                                                    input: 'text',
                                                                    props: { mask: '##.##.####', extends: 'date' },
                                                                    name: 'dateOfCancellation'
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            type: 'FormConditionalElement',
                                                            props: { conditionName: 'show_calendarWidget', revert: true },
                                                            children: [
                                                                {
                                                                    type: 'FormField',
                                                                    input: 'text',
                                                                    props: { mask: '##.##.####' },
                                                                    name: 'dateOfCancellation'
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12 },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    name: 'meterNumber',
                                                    input: 'text'
                                                }
                                            ]
                                        },
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12 },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    name: 'maLoID',
                                                    input: 'text'
                                                }
                                            ]
                                        },
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12 },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    name: 'startOfDelivery',
                                                    input: 'radio'
                                                },
                                                {
                                                    type: 'FormConditionalElement',
                                                    props: { conditionName: 'isDesiredDate' },
                                                    children: [
                                                        {
                                                            type: 'FormConditionalElement',
                                                            props: { conditionName: 'show_calendarWidget' },
                                                            children: [
                                                                {
                                                                    type: 'FormField',
                                                                    input: 'text',
                                                                    props: {
                                                                        mask: '##.##.####',
                                                                        extends: 'date'
                                                                    },
                                                                    name: 'desiredDate'
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            type: 'FormConditionalElement',
                                                            props: { conditionName: 'show_calendarWidget', revert: true },
                                                            children: [
                                                                {
                                                                    type: 'FormField',
                                                                    input: 'text',
                                                                    props: {
                                                                        mask: '##.##.####'
                                                                    },
                                                                    name: 'desiredDate'
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            type: 'FormText',
                                                            props: {
                                                                text: 'Ist die Belieferung zu Ihrem Wunschtermin nicht möglich, erfolgt die Belieferung zum nächstmöglichen Termin nach Ihrem Wunschtermin.',
                                                                level: 'body2'
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                { type: 'FormDiv', props: { class: 'q-my-lg' }, children: [] },
                {
                    type: 'FormConditionalElement',
                    props: { conditionName: 'isChangeTariff' },
                    children: [
                        {
                            type: 'FormCard',
                            props: { title: 'Tarifwechsel' },
                            children: [
                                {
                                    type: 'FormRow',
                                    props: { classes: 'tr-flex-wrap' },
                                    children: [
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12 },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    name: 'prevCustomerNum',
                                                    input: 'text'
                                                }
                                            ]
                                        },
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12 },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    name: 'meterNumber',
                                                    input: 'text'
                                                }
                                            ]
                                        },
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12 },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    name: 'startOfDelivery',
                                                    input: 'radio'
                                                },
                                                {
                                                    type: 'FormConditionalElement',
                                                    props: { conditionName: 'isDesiredDate' },
                                                    children: [
                                                        {
                                                            type: 'FormConditionalElement',
                                                            props: { conditionName: 'show_calendarWidget' },
                                                            children: [
                                                                {
                                                                    type: 'FormField',
                                                                    input: 'text',
                                                                    props: {
                                                                        mask: '##.##.####',
                                                                        extends: 'date'
                                                                    },
                                                                    name: 'desiredDate'
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            type: 'FormConditionalElement',
                                                            props: { conditionName: 'show_calendarWidget', revert: true },
                                                            children: [
                                                                {
                                                                    type: 'FormField',
                                                                    input: 'text',
                                                                    props: {
                                                                        mask: '##.##.####'
                                                                    },
                                                                    name: 'desiredDate'
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            type: 'FormText',
                                                            props: {
                                                                text: 'Ist die Belieferung zu Ihrem Wunschtermin nicht möglich, erfolgt die Belieferung zum nächstmöglichen Termin nach Ihrem Wunschtermin.',
                                                                level: 'body2'
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            type: 'FormConditionalElement',
            props: { conditionName: 'isTelco' },
            children: [
                {
                    type: 'FormCard',
                    props: {},
                    children: [
                        {
                            type: 'FormRow',
                            props: {},
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'startOfDelivery',
                                            input: 'radio'
                                        },
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'isDesiredDate' },
                                            children: [
                                                {
                                                    type: 'FormConditionalElement',
                                                    props: { conditionName: 'show_calendarWidget' },
                                                    children: [
                                                        {
                                                            type: 'FormField',
                                                            input: 'text',
                                                            props: {
                                                                mask: '##.##.####',
                                                                extends: 'date'
                                                            },
                                                            name: 'desiredDate'
                                                        }
                                                    ]
                                                },
                                                {
                                                    type: 'FormConditionalElement',
                                                    props: { conditionName: 'show_calendarWidget', revert: true },
                                                    children: [
                                                        {
                                                            type: 'FormField',
                                                            input: 'text',
                                                            props: {
                                                                mask: '##.##.####'
                                                            },
                                                            name: 'desiredDate'
                                                        }
                                                    ]
                                                },
                                                {
                                                    type: 'FormText',
                                                    props: {
                                                        text: 'Ist die Belieferung zu Ihrem Wunschtermin nicht möglich, erfolgt die Belieferung zum nächstmöglichen Termin nach Ihrem Wunschtermin.',
                                                        level: 'body2'
                                                    }
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            type: 'FormConditionalElement',
            props: { conditionName: 'isMoveOut' },
            children: [
                {
                    type: 'FormCard',
                    props: { title: 'Ich ziehe um' },
                    children: [
                        {
                            type: 'FormRow',
                            props: { classes: 'tr-flex-wrap' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'isReasonToChangeMoveExistingCustomer' },
                                            children: [
                                                {
                                                    type: 'FormCol',
                                                    props: { sm: 8, md: 6 },
                                                    children: [{ type: 'FormField', name: 'newCustomer', input: 'checkbox' }]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'isNewCustomer', revert: true },
                                            children: [
                                                {
                                                    type: 'FormCol',
                                                    props: { xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'FormField',
                                                            name: 'prevCustomerNum',
                                                            input: 'text'
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormField',
                                            input: 'text',
                                            name: 'newMeterNumber'
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'show_calendarWidget' },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    input: 'text',
                                                    name: 'dateMoveIn',
                                                    props: { mask: '##.##.####', extends: 'date' }
                                                }
                                            ]
                                        },
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'show_calendarWidget', revert: true },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    input: 'text',
                                                    name: 'dateMoveIn',
                                                    props: { mask: '##.##.####' }
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'isHTNT', revert: false },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    input: 'number',
                                                    name: 'currentMeterReading'
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'isHTNT', revert: true },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    input: 'number',
                                                    name: 'currentMeterReadingHT'
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'isHTNT', revert: true },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    input: 'number',
                                                    name: 'currentMeterReadingNT'
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormText',
                                            props: { text: 'Bitte nur ganze Zahlen eingeben, ohne Nachkommastellen.', level: 'body2' }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        { type: 'FormDiv', props: { class: 'q-my-lg' }, children: [] },
        {
            type: 'FormConditionalElement',
            props: { conditionName: 'isTelcoProduct' },
            children: [
                {
                    type: 'FormCard',
                    props: {},
                    children: [
                        {
                            type: 'FormRow',
                            props: {},
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'numberPortability',
                                            input: 'radio',
                                            props: { html: true }
                                        },
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'isNumberPortabilityNew' },
                                            children: [
                                                {
                                                    type: 'FormDiv',
                                                    props: { class: 'q-pt-md q-px-sm' },
                                                    children: [
                                                        {
                                                            type: 'FormText',
                                                            props: {
                                                                text: '',
                                                                level: 'body2'
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    type: 'FormCol',
                                                    props: { xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'FormField',
                                                            name: 'prevTelNumber',
                                                            input: 'text',
                                                            props: {
                                                                extends: 'multi',
                                                                mask: '############'
                                                            }
                                                        }
                                                    ]
                                                },
                                                /*     {
                                                  type: 'FormConditionalElement',
                                                  props: { conditionName: 'isShowPreviousProvider' },
                                                  children: [
                                                {
                                                  type: 'FormCol',
                                                  props: { xs: 12 },
                                                  children: [
                                                    {
                                                      type: 'FormField',
                                                      name: 'previous_provider',
                                                      input: 'text'
                                                    },
                                                  ]
                                                }]}, */
                                                {
                                                    type: 'FormConditionalElement',
                                                    props: { conditionName: 'isShowPreviousProvider' },
                                                    children: [
                                                        {
                                                            type: 'FormField',
                                                            name: 'previous_provideList',
                                                            input: 'select',
                                                            props: {
                                                                filterable: true
                                                            }
                                                        },
                                                        {
                                                            type: 'FormConditionalElement',
                                                            props: { conditionName: 'isShowPreviousProviderCustom' },
                                                            children: [
                                                                {
                                                                    type: 'FormCol',
                                                                    props: { xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'FormField',
                                                                            name: 'previous_provider',
                                                                            input: 'text'
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                /* GETTR-1221 */
                                                {
                                                    type: 'FormConditionalElement',
                                                    props: { conditionName: 'isShowPreviousSubscriber' },
                                                    children: [
                                                        {
                                                            type: 'FormText',
                                                            props: {
                                                                text: 'Anschlussinhaber',
                                                                level: 'body1'
                                                            }
                                                        },
                                                        {
                                                            type: 'FormCol',
                                                            props: { xs: 12 },
                                                            children: [
                                                                {
                                                                    type: 'FormField',
                                                                    name: 'previousSubscriber_firstname',
                                                                    input: 'text'
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            type: 'FormCol',
                                                            props: { xs: 12 },
                                                            children: [
                                                                {
                                                                    type: 'FormField',
                                                                    name: 'previousSubscriber_lastname',
                                                                    input: 'text'
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            type: 'FormCol',
                                                            props: { xs: 12 },
                                                            children: [
                                                                {
                                                                    type: 'FormField',
                                                                    name: 'previousSubscriber_company',
                                                                    input: 'text'
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'evn',
                                            input: 'select'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        { type: 'FormDiv', props: { class: 'q-my-lg' }, children: [] },
        {
            type: 'FormConditionalElement',
            props: { conditionName: 'showPhonebookEntry' },
            children: [
                {
                    type: 'FormCard',
                    props: {},
                    children: [
                        {
                            type: 'FormRow',
                            props: {},
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'subscriberDirectory',
                                            input: 'radio',
                                            props: { html: true }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        { type: 'FormDiv', props: { class: 'q-my-lg' }, children: [] },
        {
            type: 'FormConditionalElement',
            props: { conditionName: 'showPhonebookEntryExtended' },
            children: [
                {
                    type: 'FormCard',
                    props: {},
                    children: [
                        {
                            type: 'FormRow',
                            props: {},
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'phonebook',
                                            input: 'radio',
                                            props: { html: true }
                                        },
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'showPhonebook' },
                                            children: [
                                                {
                                                    type: 'FormCol',
                                                    props: { xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'FormField',
                                                            name: 'phonebookPrint',
                                                            input: 'checkbox'
                                                        },
                                                        {
                                                            type: 'FormField',
                                                            name: 'phonebookDigital',
                                                            input: 'checkbox'
                                                        },
                                                        {
                                                            type: 'FormField',
                                                            name: 'phonebookEnquiry',
                                                            input: 'checkbox'
                                                        },
                                                        {
                                                            type: 'FormConditionalElement',
                                                            props: { conditionName: 'isPhonebookEnquiry' },
                                                            children: [
                                                                {
                                                                    type: 'FormField',
                                                                    name: 'phonebookEnquiryType',
                                                                    input: 'radio',
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            type: 'FormField',
                                                            name: 'phonebookDenyReverseSearch',
                                                            input: 'checkbox'
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        { type: 'FormDiv', props: { class: 'q-my-lg' }, children: [] },
        {
            type: 'FormConditionalElement',
            props: { conditionName: 'isShowPreviousTenant' },
            children: [
                {
                    type: 'FormCard',
                    props: { title: 'Vormieter' },
                    children: [
                        {
                            type: 'FormRow',
                            props: {},
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'previousTenant_firstname',
                                            input: 'text'
                                        },
                                        {
                                            type: 'FormField',
                                            name: 'previousTenant_lastname',
                                            input: 'text'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            type: 'FormDiv',
            props: { class: 'q-pt-md q-px-sm' },
            children: [{ type: 'FormText', props: { text: 'Pflichtfelder sind mit einem Stern * gekennzeichnet.', level: 'body2' } }]
        },
        {
            type: 'FormRow',
            props: { justify: 'between', classes: 'q-py-md' },
            children: [
                {
                    type: 'FormCol',
                    props: { sm: 4, lg: 3 },
                    children: [{ type: 'FormBackButton', props: { title: 'Zurück', classes: 'full-width' } }]
                },
                {
                    type: 'FormCol',
                    props: { sm: 4, lg: 3 },
                    children: [
                        {
                            type: 'FormConditionalElement',
                            props: { conditionName: 'showOfferButton', revert: false, transition: false },
                            children: [
                                {
                                    type: 'FormClickButton',
                                    props: { title: 'Angebot erstellen' }
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'FormCol',
                    props: { sm: 4, lg: 3 },
                    children: [{ type: 'FormSubmitButton', props: { title: 'Weiter', classes: 'full-width' } }]
                }
            ]
        }
    ]
};
export default preSupplierForm;
