import { __assign, __awaiter, __decorate, __extends, __generator, __read } from "tslib";
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { ClosePopup, QBtn, QCard, QCardActions, QCardSection, QDialog, QInnerLoading, QSeparator, QSpace, QSpinnerDots, QTab, QTabPanel, QTabPanels, QTabs } from 'quasar';
import Toast from '../../components/toast/Toast.vue';
import { getSubject } from '../../utils/contactFormMailing';
import { getPrimaAddressData } from '../../utils/PrimaAdressData';
import { createAPIKey } from '../../utils/serviceProxy';
import { createGetLocation, createGetResults } from '../../utils/simplifier';
import useTariffWebService from '../../utils/tarif';
import { getCustomerData } from '../../utils/vppCustomerData';
import { getLocationFilterData } from '../../utils/vppLocationFilter';
import CancellationForm from '../CancellationForm';
import ContactForm from '../ContactForm';
import TariffConfirmation from '../TariffCalculatorConfirmation';
import TariffCalculatorContractConclusion from '../TariffCalculatorContractConclusion';
import TariffCalculatorInput from '../TariffCalculatorInput';
import TariffCalculatorResults from '../TariffCalculatorResults';
import { usageOptionsElectricity, usageOptionsGas } from './mockData';
var stages = ['input', 'results', 'conclusion', 'confirmation'];
var TariffCalculator = /** @class */ (function (_super) {
    __extends(TariffCalculator, _super);
    function TariffCalculator() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isVppReload = false;
        _this.isDialogShow = false;
        _this.showCancelationForm = false;
        _this.mappingID = 0;
        _this.calculatorIDsConfig = {};
        _this.index = 0;
        _this.inputData = null;
        _this.selectedTariff = null;
        _this.compareTariff = undefined;
        _this.tariffResults = [];
        _this.simplifierResults = null;
        _this.simplifierTelcoResults = null;
        _this.supplierInformation = null;
        _this.calculationParameter = null;
        _this.conclusionData = null;
        _this.context = 'electricity';
        _this.calculatorTypeId = '1';
        _this.DEFAULT_RENDER_LIST = 200;
        _this.labelID = 0;
        _this.zipCode = '';
        _this.city = '';
        _this.street = '';
        _this.houseNumber = '';
        _this.usageHT = 0;
        _this.usageNT = 0;
        _this.tariff = '';
        _this.bestandskundenTarif = 3;
        _this.smartmeter = 3;
        _this.sonderabschlag = 3;
        _this.kaution = 3;
        _this.preisgarantie = 3;
        _this.bonus = 3;
        _this.portalRichtlinie = 3;
        _this.paket = 3;
        _this.vorkasse = 3;
        _this.fixpreis = 3;
        _this.vergleichsRichtlinie = 10;
        _this.handlingTarif = 3;
        _this.grundversorgungstarife = 3;
        _this.tarifeVonGrundversorgern = 3;
        _this.grenzenBeiATignorierenBis = 20000;
        _this.tariftyp = '1';
        _this.netzbetreiber = '';
        _this.grundversorger = '';
        _this.netzgebiet = '';
        _this.kundenart = 0;
        _this.eco = false;
        _this.seitenGroesse = 20;
        _this.seitenNr = 1;
        _this.vertragslaufzeitBis = 0;
        _this.kuendigungsfrist = 0;
        _this.preisgarantieDauer = 0;
        _this.useTwoTariffs = 0;
        _this.versorgerIDs = '';
        _this.sortierung = 1;
        _this.details = 1;
        _this.automaticOff = false;
        _this.isLabelsReady = false;
        _this.contactFormSubject = [];
        _this.optVPPCustomerResponse = null;
        _this.optVPPLocationFilter = null;
        _this.optPrimaAddressResponse = null;
        _this.isLoading = false;
        return _this;
    }
    TariffCalculator.prototype.created = function () {
        this.calculatorTypeId = this.tariffCalculatorTypeId;
        switch (this.calculatorTypeId) {
            default:
                this.context = 'electricity';
                break;
            case '1':
                this.context = 'electricity';
                break;
            case '2':
                this.context = 'gas';
                break;
            case '3':
                this.context = 'heating';
                this.tariftyp = '3';
                break;
            case '4':
                this.context = 'autostrom';
                this.tariftyp = '1';
                break;
            case '7':
                this.context = 'telco';
                break;
        }
        if (this.authToken) {
            this.getLocationFilter();
        }
        this.isVppReload = true;
        if (this.opt_vppCustomerID && this.opt_vppCustomerID !== '0' && this.authToken) {
            this.getVPPCustomerData();
            this.isVppReload = false;
        }
        if (this.opt_primaAddressID && this.opt_primaAddressID !== '0') {
            this.getPrimaAddressData();
            this.isVppReload = false;
        }
    };
    Object.defineProperty(TariffCalculator.prototype, "externAddressID", {
        get: function () {
            var _a, _b;
            if (this.optPrimaAddressResponse)
                return (_b = (_a = this.optPrimaAddressResponse) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.id;
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculator.prototype, "overrideZipCode", {
        get: function () {
            var _a, _b, _c, _d, _e, _f, _g;
            if (this.optVPPCustomerResponse && ((_a = this.optVPPCustomerResponse.person) === null || _a === void 0 ? void 0 : _a.shippingAddress))
                return (_c = (_b = this.optVPPCustomerResponse.person) === null || _b === void 0 ? void 0 : _b.shippingAddress) === null || _c === void 0 ? void 0 : _c.zipcode;
            if (this.optVPPCustomerResponse && ((_d = this.optVPPCustomerResponse.person) === null || _d === void 0 ? void 0 : _d.installationAddress))
                return (_f = (_e = this.optVPPCustomerResponse.person) === null || _e === void 0 ? void 0 : _e.installationAddress) === null || _f === void 0 ? void 0 : _f.zipcode;
            if (this.optPrimaAddressResponse)
                return (_g = this.optPrimaAddressResponse.data) === null || _g === void 0 ? void 0 : _g.zipCode;
            if (this.opt_plz)
                return this.opt_plz;
            return '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculator.prototype, "overrideCity", {
        get: function () {
            var _a, _b, _c, _d, _e, _f;
            if (this.optVPPCustomerResponse && ((_a = this.optVPPCustomerResponse.person) === null || _a === void 0 ? void 0 : _a.shippingAddress))
                return (_c = (_b = this.optVPPCustomerResponse.person) === null || _b === void 0 ? void 0 : _b.shippingAddress) === null || _c === void 0 ? void 0 : _c.city;
            if (this.optVPPCustomerResponse && ((_d = this.optVPPCustomerResponse.person) === null || _d === void 0 ? void 0 : _d.installationAddress))
                return (_f = (_e = this.optVPPCustomerResponse.person) === null || _e === void 0 ? void 0 : _e.installationAddress) === null || _f === void 0 ? void 0 : _f.city;
            if (this.optPrimaAddressResponse)
                return this.optPrimaAddressResponse.data.city;
            if (this.opt_ort)
                return this.opt_ort;
            return '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculator.prototype, "overrideStreet", {
        get: function () {
            var _a, _b, _c, _d, _e, _f;
            if (this.optVPPCustomerResponse && ((_a = this.optVPPCustomerResponse.person) === null || _a === void 0 ? void 0 : _a.shippingAddress))
                return (_c = (_b = this.optVPPCustomerResponse.person) === null || _b === void 0 ? void 0 : _b.shippingAddress) === null || _c === void 0 ? void 0 : _c.street;
            if (this.optVPPCustomerResponse && ((_d = this.optVPPCustomerResponse.person) === null || _d === void 0 ? void 0 : _d.installationAddress))
                return (_f = (_e = this.optVPPCustomerResponse.person) === null || _e === void 0 ? void 0 : _e.installationAddress) === null || _f === void 0 ? void 0 : _f.street;
            if (this.optPrimaAddressResponse)
                return this.optPrimaAddressResponse.data.street;
            if (this.opt_strasse)
                return this.opt_strasse;
            return '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculator.prototype, "overrideHouseNumber", {
        get: function () {
            var _a, _b, _c, _d, _e, _f;
            if (this.optVPPCustomerResponse && ((_a = this.optVPPCustomerResponse.person) === null || _a === void 0 ? void 0 : _a.shippingAddress))
                return (_c = (_b = this.optVPPCustomerResponse.person) === null || _b === void 0 ? void 0 : _b.shippingAddress) === null || _c === void 0 ? void 0 : _c.house_no;
            if (this.optVPPCustomerResponse && ((_d = this.optVPPCustomerResponse.person) === null || _d === void 0 ? void 0 : _d.installationAddress))
                return (_f = (_e = this.optVPPCustomerResponse.person) === null || _e === void 0 ? void 0 : _e.installationAddress) === null || _f === void 0 ? void 0 : _f.house_no;
            if (this.optPrimaAddressResponse)
                return this.optPrimaAddressResponse.data.houseNumberComplete;
            if (this.opt_hausnummer)
                return this.opt_hausnummer;
            return '';
        },
        enumerable: false,
        configurable: true
    });
    TariffCalculator.prototype.getLocationFilter = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, getLocationFilterData(this.authToken)];
                    case 1:
                        _a.optVPPLocationFilter = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TariffCalculator.prototype.getVPPCustomerData = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = this;
                        return [4 /*yield*/, getCustomerData(this.authToken, this.opt_vppCustomerID)];
                    case 1:
                        _b.optVPPCustomerResponse = _c.sent();
                        console.log('this.optVPPCustomerResponse', this.optVPPCustomerResponse);
                        if (this.optVPPCustomerResponse && this.optVPPCustomerResponse.person && this.optVPPCustomerResponse.person.addresses.length > 0) {
                            this.optVPPCustomerResponse.person.shippingAddress = this.optVPPCustomerResponse.person.addresses.find(function (a) { return a.address_type === 'SHIPPING'; });
                            this.optVPPCustomerResponse.person.billingAddress = this.optVPPCustomerResponse.person.addresses.find(function (a) { return a.address_type === 'BILLING'; });
                            this.optVPPCustomerResponse.person.installationAddress = this.optVPPCustomerResponse.person.addresses.find(function (a) { return a.address_type === 'INSTALLATION'; });
                            if (this.optVPPCustomerResponse && ((_a = this.optVPPCustomerResponse.person) === null || _a === void 0 ? void 0 : _a.installationAddress)) {
                                this.tariffCalculatorTypeId = '7';
                                this.calculatorTypeId = '7';
                                this.context = 'telco';
                            }
                            if (this.optVPPCustomerResponse.person.shippingAddress === undefined)
                                // eslint-disable-next-line prefer-destructuring
                                this.optVPPCustomerResponse.person.shippingAddress = this.optVPPCustomerResponse.person.addresses[0];
                            this.isVppReload = true;
                        }
                        this.isVppReload = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    TariffCalculator.prototype.getPrimaAddressData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, getPrimaAddressData(this.opt_primaAddressID)];
                    case 1:
                        _a.optPrimaAddressResponse = _b.sent();
                        if (this.optPrimaAddressResponse) {
                            this.isVppReload = true;
                        }
                        this.isVppReload = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(TariffCalculator.prototype, "loadAutomaticResult", {
        get: function () {
            return this.optPrimaAddressResponse != null || (this.opt_plz && !this.automaticOff && (this.opt_verbrauchStrom > 0 || this.opt_verbrauchGas > 0));
            // return this.optVPPCustomerResponse!= null  || (this.opt_plz && !this.automaticOff && (this.opt_verbrauchStrom > 0 || this.opt_verbrauchGas > 0));
            //   return this.opt_plz && !this.automaticOff && (this.opt_verbrauchStrom > 0 || this.opt_verbrauchGas > 0);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculator.prototype, "isBrowserSupport", {
        get: function () {
            var version = this.getBrowser().version;
            var lastTwoVersion = version - 2;
            return version >= lastTwoVersion;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculator.prototype, "isGasContext", {
        get: function () {
            return this.context === 'gas';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculator.prototype, "isHeizstromContext", {
        get: function () {
            return this.context === 'heating';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculator.prototype, "isAutostromContext", {
        get: function () {
            return this.context === 'autostrom';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculator.prototype, "isTelcoContext", {
        get: function () {
            return this.context === 'telco';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculator.prototype, "customLabelsInput", {
        get: function () {
            if (this.isGasContext) {
                return {
                    onlyEco: 'nur Ökogas',
                    search: 'Gastarife vergleichen'
                };
            }
            if (this.isHeizstromContext) {
                return {
                    onlyEco: 'nur Ökostrom',
                    search: 'Heizstromtarife vergleichen'
                };
            }
            if (this.isAutostromContext) {
                return {
                    onlyEco: 'nur Ökostrom',
                    search: 'Autostromtarife vergleichen'
                };
            }
            if (this.isTelcoContext) {
                return {
                    onlyEco: '',
                    search: 'Produktsuche'
                };
            }
            return {
                onlyEco: 'nur Ökostrom',
                search: 'Stromtarife vergleichen'
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculator.prototype, "stage", {
        get: function () {
            return stages[this.index % stages.length];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculator.prototype, "locationChangeHandler", {
        get: function () {
            return createGetLocation(this.calculatorTypeId, this.context);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculator.prototype, "inputParametersChangeHandler", {
        get: function () {
            return createGetResults(this.context);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculator.prototype, "usageOptions", {
        get: function () {
            if (this.isGasContext)
                return usageOptionsGas;
            return usageOptionsElectricity;
        },
        enumerable: false,
        configurable: true
    });
    TariffCalculator.prototype.previousStep = function () {
        this.labelID = 0;
        this.index -= 1;
        this.scrollTop();
    };
    TariffCalculator.prototype.nextStep = function () {
        this.labelID = 0;
        this.index += 1;
        this.scrollTop();
    };
    TariffCalculator.prototype.goBackToTR = function () {
        var _a;
        this.isDialogShow = false;
        if ((_a = this.inputData) === null || _a === void 0 ? void 0 : _a.tariffCalculatorTypeId)
            this.tariffCalculatorTypeId = this.inputData.tariffCalculatorTypeId;
    };
    TariffCalculator.prototype.scrollTop = function (yOffset, delay) {
        if (yOffset === void 0) { yOffset = -15; }
        if (delay === void 0) { delay = 500; }
        var tariffCalculatorContainer = this.tariffCalculatorContainer;
        if (tariffCalculatorContainer) {
            setTimeout(function () {
                var pageYOffset = window.pageYOffset, scrollTo = window.scrollTo;
                var top = (tariffCalculatorContainer === null || tariffCalculatorContainer === void 0 ? void 0 : tariffCalculatorContainer.getBoundingClientRect()).top;
                var finalYPosition = top + pageYOffset + yOffset;
                scrollTo({ top: finalYPosition, behavior: 'smooth' });
            }, delay);
        }
    };
    TariffCalculator.prototype.getProcessMapping = function () {
        var _this = this;
        var _a, _b;
        var mapping = [];
        (_b = (_a = this.calculatorIDsConfig) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.processmapping.forEach(function (f) {
            if (f.calculatorTypeID === _this.calculatorTypeId)
                mapping = f.mapping;
        });
        return mapping;
    };
    TariffCalculator.prototype.getMappedStatusId = function (externID) {
        var _a;
        var index = this.getProcessMapping().findIndex(function (s) { return s.status_extern === externID; });
        return (_a = this.getProcessMapping()[index]) === null || _a === void 0 ? void 0 : _a.pid_intern;
    };
    TariffCalculator.prototype.getResults = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var res, _d, data;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (!this.inputData) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.inputParametersChangeHandler(this.inputData)];
                    case 1:
                        res = _e.sent();
                        if (this.isTelcoContext) {
                            if (res === null || res === void 0 ? void 0 : res.telcoGridConnection) {
                                this.inputData.houseNumberAddition = res === null || res === void 0 ? void 0 : res.telcoGridConnection.houseNumberAddition;
                                this.inputData.houseNumber = res === null || res === void 0 ? void 0 : res.telcoGridConnection.houseNumberComplete;
                            }
                            this.inputData.gridConnectionStatus = res === null || res === void 0 ? void 0 : res.gridConnectionStatus;
                            this.mappingID = this.getMappedStatusId(this.inputData.gridConnectionStatus);
                            this.inputData.externLocationId = res === null || res === void 0 ? void 0 : res.externLocationId;
                            this.simplifierTelcoResults = res ? (_a = res.calculationTelcoResult) === null || _a === void 0 ? void 0 : _a.products : [];
                            this.calculationParameter = (_b = res === null || res === void 0 ? void 0 : res.calculationParameter) !== null && _b !== void 0 ? _b : null;
                            this.inputData.gridConnectionStatus = res === null || res === void 0 ? void 0 : res.gridConnectionStatus;
                            this.inputData.telcoGridConnection = res === null || res === void 0 ? void 0 : res.telcoGridConnection;
                            this.inputData.externLocationId = res === null || res === void 0 ? void 0 : res.externLocationId;
                        }
                        else {
                            _d = __read(res ? res.calculationResult.ergebnisContainer : [], 1), data = _d[0];
                            this.simplifierResults = data === null || data === void 0 ? void 0 : data.ergebnis.slice(0, this.DEFAULT_RENDER_LIST);
                            this.supplierInformation = data === null || data === void 0 ? void 0 : data.versorgerInformation;
                            this.calculationParameter = (_c = res === null || res === void 0 ? void 0 : res.calculationParameter) !== null && _c !== void 0 ? _c : null;
                        }
                        _e.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    TariffCalculator.prototype.getBrowser = function () {
        var ua = navigator.userAgent;
        var _a = __read(ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [], 3), browser = _a[1], version = _a[2];
        return {
            browser: browser,
            version: parseFloat(version)
        };
    };
    TariffCalculator.prototype.changeCompareTariff = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        // await this.getProxyLabels();
                        return [4 /*yield*/, this.getResults()];
                    case 2:
                        // await this.getProxyLabels();
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        throw new Error(e_1.message);
                    case 4:
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    TariffCalculator.prototype.onChangeResultCustomerType = function (inputResult) {
        return __awaiter(this, void 0, void 0, function () {
            var e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = true;
                        this.inputData = inputResult;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.getResults()];
                    case 2:
                        _a.sent();
                        this.isLoading = false;
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        console.error(e_2);
                        throw e_2;
                    case 4:
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    TariffCalculator.prototype.onInputSubmitForm = function (inputResult) {
        return __awaiter(this, void 0, void 0, function () {
            var e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.inputData = inputResult;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        return [4 /*yield*/, this.getResults()];
                    case 2:
                        _a.sent();
                        if (!(this.mappingID === 2 && this.isTelcoContext)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.prepareContactForm()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        this.automaticOff = true;
                        this.nextStep();
                        this.scrollTop(-15, 800);
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        e_3 = _a.sent();
                        throw new Error(e_3.message);
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    TariffCalculator.prototype.prepareContactForm = function () {
        return __awaiter(this, void 0, void 0, function () {
            var apikey, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.getAPIKey()];
                    case 1:
                        apikey = _b.sent();
                        _a = this;
                        return [4 /*yield*/, getSubject(this.calculatorTypeId, this.mappingID, apikey)];
                    case 2:
                        _a.contactFormSubject = _b.sent();
                        this.isDialogShow = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    TariffCalculator.prototype.prepareTHGContactForm = function (inputResult) {
        return __awaiter(this, void 0, void 0, function () {
            var apikey, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.getAPIKey()];
                    case 1:
                        apikey = _b.sent();
                        this.inputData = inputResult;
                        this.inputData.tariffCalculatorTypeId = '8';
                        _a = this;
                        return [4 /*yield*/, getSubject('8', this.mappingID, apikey)];
                    case 2:
                        _a.contactFormSubject = _b.sent();
                        this.isDialogShow = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    TariffCalculator.prototype.prepareCancelationForm = function () {
        this.showCancelationForm = true;
    };
    TariffCalculator.prototype.onInputLiteSubmitForm = function (inputResult) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var extParam, url, url;
            return __generator(this, function (_c) {
                this.inputData = inputResult;
                extParam = '';
                if (this.opt_versorgerIDs)
                    extParam = extParam.concat('&versorgerIDs=').concat(this.opt_versorgerIDs);
                if (this.opt_bonus)
                    extParam = extParam.concat('&bonus=').concat(this.opt_bonus.toString());
                if (this.opt_markenIDs)
                    extParam = extParam.concat('&markenIDs=').concat(this.opt_markenIDs);
                if (this.opt_tarifRootIDs)
                    extParam = extParam.concat('&tarifRootIDs=').concat(this.opt_tarifRootIDs);
                if (this.opt_tarifRootIDsMindestfilter)
                    extParam = extParam.concat('&tarifRootIDsMindestfilter=').concat(this.opt_tarifRootIDsMindestfilter);
                if (this.opt_zaehlerart)
                    extParam = extParam.concat('&zaehlerart=').concat(this.opt_zaehlerart.toString());
                if (this.opt_baiextendedWhiteList)
                    extParam = extParam.concat('&baiextendedWhiteList=').concat(this.opt_baiextendedWhiteList);
                if (this.opt_stichtag)
                    extParam = extParam.concat('&stichtag=').concat(this.opt_stichtag);
                if (this.opt_tarifcode)
                    extParam = extParam.concat('&tarifcode=').concat(this.opt_tarifcode);
                if (this.opt_handlingTarife)
                    extParam = extParam.concat('&handlingTarife=').concat(this.opt_handlingTarife.toString());
                if (this.opt_oeko)
                    extParam = extParam.concat('&oeko=').concat(this.opt_oeko.toString());
                if (this.opt_kundenart)
                    extParam = extParam.concat('&kundenart').concat(this.opt_kundenart.toString());
                if (this.inputData.tariffCalculatorTypeId === '2') {
                    url = this.onlyInputTarget
                        .concat('?tariffCalculatorTypeId=')
                        .concat(this.inputData.tariffCalculatorTypeId.toString())
                        .concat('&verbrauchGas=')
                        .concat(this.inputData.usageHT.toString())
                        .concat('&plz=')
                        .concat(this.inputData.zipCode)
                        .concat(extParam);
                    if (this.inputData.city)
                        url = url.concat('&ort=').concat(this.inputData.city.replace('|', 'slash'));
                    if (this.inputData.street)
                        url = url.concat('&strasse=').concat(this.inputData.street);
                    if (this.inputData.houseNumber)
                        url = url.concat('&hausnummer=').concat((_a = this.inputData.houseNumber) === null || _a === void 0 ? void 0 : _a.toString());
                    window.open(url, '_self');
                }
                else {
                    url = this.onlyInputTarget
                        .concat('?tariffCalculatorTypeId=')
                        .concat(this.inputData.tariffCalculatorTypeId.toString())
                        .concat('&verbrauchStrom=')
                        .concat(this.inputData.usageHT.toString())
                        .concat('&plz=')
                        .concat(this.inputData.zipCode)
                        .concat(extParam);
                    if (this.inputData.city)
                        url = url.concat('&ort=').concat(this.inputData.city.replace('|', 'slash'));
                    if (this.inputData.street)
                        url = url.concat('&strasse=').concat(this.inputData.street);
                    if (this.inputData.houseNumber)
                        url = url.concat('&hausnummer=').concat((_b = this.inputData.houseNumber) === null || _b === void 0 ? void 0 : _b.toString());
                    window.open(url, '_self');
                }
                return [2 /*return*/];
            });
        });
    };
    TariffCalculator.prototype.onResultsSelectTariff = function (selectedTariff) {
        var _a;
        if (!selectedTariff)
            return undefined;
        var verbandsnummer = this.supplierInformation[0].marktrollen[0].verbandsnummer;
        this.selectedTariff = __assign(__assign({}, selectedTariff), { verbandsnummer: verbandsnummer });
        var indexVergleichstarif = (_a = this.simplifierResults) === null || _a === void 0 ? void 0 : _a.findIndex(function (f) { return f.isVergleichsTarif; });
        if (this.simplifierResults != null && indexVergleichstarif && indexVergleichstarif > 0 && this.simplifierResults[indexVergleichstarif])
            this.compareTariff = this.simplifierResults[indexVergleichstarif];
        return this.nextStep();
    };
    TariffCalculator.prototype.onResultsSelectTelcoTariff = function (selectedTelcoTariff) {
        if (!selectedTelcoTariff)
            return undefined;
        var verbandsnummer = '';
        this.selectedTariff = __assign(__assign({}, selectedTelcoTariff), { verbandsnummer: verbandsnummer });
        return this.nextStep();
    };
    TariffCalculator.prototype.getAPIKey = function () {
        return __awaiter(this, void 0, void 0, function () {
            var apikey;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        apikey = this.$q.cookies.get('adserv');
                        if (!!apikey) return [3 /*break*/, 2];
                        return [4 /*yield*/, createAPIKey()];
                    case 1:
                        apikey = _a.sent();
                        this.$q.cookies.set('adserv', apikey, { expires: '4m' });
                        _a.label = 2;
                    case 2: return [2 /*return*/, apikey];
                }
            });
        });
    };
    TariffCalculator.prototype.filteredResult = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return __awaiter(this, void 0, void 0, function () {
            var apikey, _l, bestandskundenTarif, smartmeter, sonderabschlag, kaution, preisgarantie, bonus, portalRichtlinie, paket, vorkasse, fixpreis, vergleichsRichtlinie, handlingTarif, grundversorgungstarife, tarifeVonGrundversorgern, grenzenBeiATignorierenBis, tariftyp, netzgebiet, vertragslaufzeitBis, kuendigungsfrist, preisgarantieDauer, seitenGroesse, seitenNr, versorgerIDs, sortierung, details, getFilterResult, data;
            return __generator(this, function (_m) {
                switch (_m.label) {
                    case 0: return [4 /*yield*/, this.getAPIKey()];
                    case 1:
                        apikey = _m.sent();
                        _l = this, bestandskundenTarif = _l.bestandskundenTarif, smartmeter = _l.smartmeter, sonderabschlag = _l.sonderabschlag, kaution = _l.kaution, preisgarantie = _l.preisgarantie, bonus = _l.bonus, portalRichtlinie = _l.portalRichtlinie, paket = _l.paket, vorkasse = _l.vorkasse, fixpreis = _l.fixpreis, vergleichsRichtlinie = _l.vergleichsRichtlinie, handlingTarif = _l.handlingTarif, grundversorgungstarife = _l.grundversorgungstarife, tarifeVonGrundversorgern = _l.tarifeVonGrundversorgern, grenzenBeiATignorierenBis = _l.grenzenBeiATignorierenBis, tariftyp = _l.tariftyp, netzgebiet = _l.netzgebiet, vertragslaufzeitBis = _l.vertragslaufzeitBis, kuendigungsfrist = _l.kuendigungsfrist, preisgarantieDauer = _l.preisgarantieDauer, seitenGroesse = _l.seitenGroesse, seitenNr = _l.seitenNr, versorgerIDs = _l.versorgerIDs, sortierung = _l.sortierung, details = _l.details;
                        getFilterResult = useTariffWebService({ apikey: apikey }, this.context).getFilterResult;
                        return [4 /*yield*/, getFilterResult({
                                netzgebiet: netzgebiet,
                                zipCode: (_a = this.inputData) === null || _a === void 0 ? void 0 : _a.zipCode,
                                city: (_b = this.inputData) === null || _b === void 0 ? void 0 : _b.city,
                                street: (_c = this.inputData) === null || _c === void 0 ? void 0 : _c.city,
                                houseNumber: (_d = this.inputData) === null || _d === void 0 ? void 0 : _d.houseNumber,
                                usageHT: (_e = this.inputData) === null || _e === void 0 ? void 0 : _e.usageHT,
                                usageNT: (_f = this.inputData) === null || _f === void 0 ? void 0 : _f.usageNT,
                                bestandskundenTarif: bestandskundenTarif,
                                smartmeter: smartmeter,
                                sonderabschlag: sonderabschlag,
                                kaution: kaution,
                                preisgarantie: preisgarantie,
                                bonus: bonus,
                                portalRichtlinie: portalRichtlinie,
                                paket: paket,
                                vorkasse: vorkasse,
                                fixpreis: fixpreis,
                                oeko: ((_g = this.inputData) === null || _g === void 0 ? void 0 : _g.onlyEco) ? 1 : 3,
                                vergleichsRichtlinie: vergleichsRichtlinie,
                                handlingTarif: handlingTarif,
                                grundversorgungstarife: grundversorgungstarife,
                                tarifeVonGrundversorgern: tarifeVonGrundversorgern,
                                grenzenBeiATignorierenBis: grenzenBeiATignorierenBis,
                                tariftyp: encodeURI(tariftyp),
                                netzbetreiber: (_h = this.inputData) === null || _h === void 0 ? void 0 : _h.netzbetreiber,
                                grundversorger: (_j = this.inputData) === null || _j === void 0 ? void 0 : _j.grundversorger,
                                kundenart: ((_k = this.inputData) === null || _k === void 0 ? void 0 : _k.customerType) ? 1 : 0,
                                vertragslaufzeitBis: vertragslaufzeitBis,
                                kuendigungsfrist: kuendigungsfrist,
                                preisgarantieDauer: preisgarantieDauer,
                                seitenGroesse: seitenGroesse,
                                seitenNr: seitenNr,
                                versorgerIDs: versorgerIDs,
                                sortierung: sortierung,
                                details: details
                            })];
                    case 2:
                        data = (_m.sent());
                        if (!data) {
                            this.simplifierResults = [];
                        }
                        else {
                            this.simplifierResults = data === null || data === void 0 ? void 0 : data.ergebnis.slice(0, this.DEFAULT_RENDER_LIST); // too much query after filtering FE can't load 800 queries so I slice only for MVP
                            this.supplierInformation = data === null || data === void 0 ? void 0 : data.versorgerInformation;
                        }
                        this.$emit('results-loaded');
                        return [2 /*return*/];
                }
            });
        });
    };
    TariffCalculator.prototype.onSubmitData = function (value) {
        this.conclusionData = value;
        this.nextStep();
    };
    TariffCalculator.prototype.onGoBack = function () {
        this.previousStep();
    };
    TariffCalculator.prototype.onGoToResult = function () {
        this.selectedTariff = null;
        this.compareTariff = undefined;
        this.previousStep();
    };
    TariffCalculator.prototype.onGoToInput = function () {
        this.index = 0;
        this.selectedTariff = null;
        this.compareTariff = undefined;
        this.scrollTop();
    };
    __decorate([
        Prop({ required: false, default: '' })
    ], TariffCalculator.prototype, "internalCss", void 0);
    __decorate([
        Prop({ required: false, default: '' })
    ], TariffCalculator.prototype, "externalCss", void 0);
    __decorate([
        Prop({ required: false, default: '1' })
    ], TariffCalculator.prototype, "tariffCalculatorTypeId", void 0);
    __decorate([
        Prop({ required: false, default: '' })
    ], TariffCalculator.prototype, "authToken", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculator.prototype, "opt_versorgerIDs", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculator.prototype, "opt_bonus", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculator.prototype, "opt_markenIDs", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculator.prototype, "opt_tarifRootIDs", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculator.prototype, "opt_tarifRootIDsMindestfilter", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculator.prototype, "opt_zaehlerart", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculator.prototype, "opt_baiextendedWhiteList", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculator.prototype, "opt_stichtag", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculator.prototype, "opt_tarifcode", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculator.prototype, "opt_handlingTarife", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculator.prototype, "opt_plz", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculator.prototype, "opt_ort", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculator.prototype, "opt_strasse", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculator.prototype, "opt_hausnummer", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculator.prototype, "opt_oeko", void 0);
    __decorate([
        Prop({ required: false, default: false })
    ], TariffCalculator.prototype, "onlyInput", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculator.prototype, "onlyInputTarget", void 0);
    __decorate([
        Prop({ required: false, default: 3200 })
    ], TariffCalculator.prototype, "opt_verbrauchStrom", void 0);
    __decorate([
        Prop({ required: false, default: 20000 })
    ], TariffCalculator.prototype, "opt_verbrauchGas", void 0);
    __decorate([
        Prop({ required: false, default: 0 })
    ], TariffCalculator.prototype, "opt_kundenart", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculator.prototype, "opt_vppTemplateID", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculator.prototype, "opt_vppCustomerID", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculator.prototype, "opt_primaAddressID", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculator.prototype, "productOverview", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculator.prototype, "opt_vppSummary", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculator.prototype, "authTokenUrl", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculator.prototype, "authTokenRefresh", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculator.prototype, "externalOfferID", void 0);
    __decorate([
        Ref('tariff-calculator-container')
    ], TariffCalculator.prototype, "tariffCalculatorContainer", void 0);
    TariffCalculator = __decorate([
        Component({
            components: {
                CancellationForm: CancellationForm,
                TariffConfirmation: TariffConfirmation,
                TariffCalculatorInput: TariffCalculatorInput,
                TariffCalculatorResults: TariffCalculatorResults,
                TariffCalculatorContractConclusion: TariffCalculatorContractConclusion,
                ContactForm: ContactForm,
                Toast: Toast,
                QBtn: QBtn,
                QCard: QCard,
                QSpace: QSpace,
                QCardActions: QCardActions,
                QCardSection: QCardSection,
                QDialog: QDialog,
                QInnerLoading: QInnerLoading,
                QSpinnerDots: QSpinnerDots,
                QSeparator: QSeparator,
                QTab: QTab,
                QTabPanel: QTabPanel,
                QTabPanels: QTabPanels,
                QTabs: QTabs
            },
            directives: {
                ClosePopup: ClosePopup
            }
        })
    ], TariffCalculator);
    return TariffCalculator;
}(Vue));
export default TariffCalculator;
